<template>
    <div style="background: #F7F9FC;color: #000;height: 100%;">
        <van-nav-bar title="月度消费情况" left-arrow :fixed="true" :z-index="999" @click-left="back"></van-nav-bar>
        <div class="tit">
            <van-row class="top_biao" type="flex" justify="space-between" style=" border-bottom: 1px solid #f6f6f6;">
                <van-col span="24" class="flix" style="justify-content: center;">
                    <div class="sleft" @click="ondaytow"></div>
                    <div>{{ TimeNametow }}</div>
                    <div class="yleft" @click="nextdaytow"></div>
                </van-col>
            </van-row>
            <van-row class="top_biao" type="flex" justify="space-between">
                <van-col span="12" class="flix pd_30" style="justify-content: space-between;">
                    <span>油漆数：</span>
                    <span style="font-weight: bold;font-size: 16px;">{{ qiheji }}</span>
                </van-col>
                <van-col span="12" class="flix pd_30" style="justify-content: space-between;">
                    <span>金额：</span>
                    <span style="font-weight: bold;font-size: 16px;">￥{{ amount }}</span>
                </van-col>
            </van-row>
        </div>
        <div style="overflow: hidden;padding-top:46px">
            <van-list v-model="loading" :finished="finished" :immediate-check="false" finished-text="没有更多了"
                @load="onLoads" :offset="10">
                <div class="list" v-for="(item, idx) in list" :key="idx">
                    <van-row class="hedrow">
                        <van-col span="6">
                            <span class="cars">{{ item.carNo }}</span>
                        </van-col>
                        <van-col span="6" class=" yichu">
                            <span>{{ item.carBrand }}</span>
                        </van-col>
                        <van-col span="12" class="tris">
                            <span style="font-weight: bold;font-size: 15px;">￥{{ item.amount }}</span>
                        </van-col>
                    </van-row>
                    <van-row class="hedrow">
                        <van-col span="8">
                            <span class="">油漆幅数：</span>
                            <span class="ys">{{ item.counts }}</span>
                        </van-col>
                    </van-row>
                    <van-row class="hedrow">
                        <van-col span="24">接车时间：{{ item.createdDate ? item.createdDate.substring(0, 19) : '' }}
                        </van-col>
                    </van-row>
                    <van-row class="hedrow">
                        <van-col span="24">交车时间：{{ item.dispatchTime }}</van-col>
                    </van-row>
                    <van-row class="hedrow">
                        <van-col span="24">施工内容：{{ item.itemStr }}</van-col>
                    </van-row>
                    <img class="cheimg" @click="fangda(item.carUrl)" v-if="item.carUrl" :src="item.carUrl" alt="" />
                </div>

            </van-list>
            <van-empty v-if="list.length < 1" description="暂无数据~" />


        </div>
    </div>
</template>

<script>
import {
    monthSource,
} from "@/api/check";
import {
    ImagePreview
} from "vant";

export default {
    //   mixins: [preventBack], //注入
    components: {},
    data() {
        return {
            list: [],
            loading: false,
            tanshow: false,
            total: 0,
            finished: false,
            payType: 1, //1微信支付 2储值卡支付
            totalHj: 0,
            certsList: [],
            source: this.$route.query.source,
            gid: this.$route.query.gid,
            Timetow: "",
            TimeNametow: "",
            page: 0,
            size: 20,
            qiheji: 0,
            amount: 0
        };
    },
    methods: {
        onLoads() {

            this.getaccountList();
        },
        ondaytow() {
            let date = new Date(this.Timetow); //获取日期  日期是2020-07 写成你自己的
            let CurrentDate = "";
            let TimeName = '';
            let year = date.getFullYear();
            let month = (date.getMonth()) < 10 ? "0" + (date.getMonth()) : (date.getMonth()); //自动补0
            if (month < 1) {
                CurrentDate = (year - 1) + "-" + "12";
                TimeName = (year - 1) + "年" + "12" + '月';
            } else {
                CurrentDate = year + "-" + month;
                TimeName = year + "年" + month + '月';
            }
            this.page = 0;
            this.list = []
            this.Timetow = CurrentDate;
            this.TimeNametow = TimeName;
            this.getaccountList();
        },
        //搜索下一月数据
        nextdaytow() {
            let date = new Date(this.Timetow);
            let CurrentDate = "";
            let TimeName = '';
            let year = date.getFullYear();
            let month = (date.getMonth() + 1 + 1) < 10 ? "0" + (date.getMonth() + 1 + 1) : (date
                .getMonth() + 1 + 1);
            if (month > 12) {
                CurrentDate = (year + 1) + "-" + "01"
                TimeName = (year + 1) + "年" + "01" + '月';
            } else {
                CurrentDate = year + "-" + month;
                TimeName = year + "年" + month + '月';
            }
            this.page = 0;
            this.list = []
            this.Timetow = CurrentDate;
            this.TimeNametow = TimeName;
            this.getaccountList();
        },
        getaccountList() {
            var data = {
                gid: this.gid,
                source: this.source,
                time: this.Timetow,
                page: this.page,
                size: this.size
            }
            monthSource(data).then((response) => {
                console.log(response)
                let rows = response.data.page.content;
                this.loading = false;
                this.total = response.data.page.totalElements;
                this.qiheji = response.data.total;
                this.amount = response.data.amount;
                if (rows == null || rows.length === 0) {
                    // 加载结束
                    this.finished = true;
                    return;
                }
                // 将新数据与老数据进行合并
                this.list = this.list.concat(rows);
                //如果列表数据条数>=总条数，不再触发滚动加载
                if (this.list.length >= this.total) {
                    this.finished = true;
                }
                this.page++;
            });
        },
        //点击放大图片
        fangda(picUrl) {
            // vant.ImagePreview([picUrl]);
            ImagePreview({
                images: [picUrl], // 需要预览的图片 URL 数组
                showIndex: false, // 是否显示页码
                closeable: true, // 是否显示关闭图标	
                closeIconPosition: 'top-right' // 关闭图标位置
            })

        },
        back() {
            this.$router.go(-1);
        }
    },
    created() {
        var nowdate = new Date();
        var y = nowdate.getFullYear();
        var m = (nowdate.getMonth() + 1).toString().padStart(2, '0');
        var currentTime = y + '-' + m;
        var TimeName = y + '年' + m + '月';
        this.TimeNametow = TimeName;
        this.Timetow = currentTime;
    },
    mounted() {
        this.getaccountList()
    },
    computed: {


    }
};
</script>

<style lang="less" scoped>
.tit {
    position: sticky;
    top: 46px;
    width: 100%;
    background: #fff;
    z-index: 9;

    .top_biao {
        text-align: center;
        font-size: 13px;
        height: 40px;
        line-height: 40px;

        .flix {
            display: flex;
            align-items: center;
        }

        .pd_30 {
            padding: 0 20px;
        }

        div {
            line-height: 25px;

        }

        .sleft {
            width: 0;
            height: 0;
            border-top: 8px solid transparent;
            border-right: 12px solid #999;
            border-bottom: 8px solid transparent;
            margin-left: 12px;
            margin-right: 10px;
        }

        .yleft {
            width: 0;
            height: 0;
            border-top: 8px solid transparent;
            border-left: 12px solid #999;
            border-bottom: 8px solid transparent;
            margin-left: 10px;
        }
    }
}

.hedrow {
    background: #fff;
    padding: 5px 10px;
    line-height: 24px;
    font-size: 13px;
    color: #333;
    position: relative;

    .van-col {
        min-height: 25px;
    }
}

.list {

    background: #fff;
    margin: 12px 10px 0 10px;
    padding: 5px 2px;
    border-radius: 4px;
    position: relative;
    font-size: 13px;
}

.cheimg {
    position: absolute;
    right: 0;
    top: 0;
    width: 110px;
    height: 80px;
    margin-top: 50px;
    margin-right: 12px;
}

.tris {
    text-align: right;
}

.right {}

.van-slider {
    width: 50%;
    vertical-align: middle;
}

.fix {
    display: flex;
    align-items: center;
    justify-content: flex-end
}

.custom-button {
    /* color: #fff; */
    /* margin-right: 20px; */
    /* .. */
    font-size: 12px;
}

.nis {
    background: #f5f5f5;
    display: inline-block;
    color: #905d1d;
    width: 35px;
    height: 22px;
    text-align: center;
    line-height: 22px;
    font-size: 13px;
    margin-top: 2px;
    /* margin-left: 4px; */
}

.reds {
    background: red;
    color: #fff;
}

.lvs {
    background: #45b97c;
}

.cars {
    background-color: #0d906e;
    display: inline-block;
    color: #fff;
    padding: 1px 4px;
    border-radius: 4px;
    font-size: 13px;
}

.ys {
    color: #faa755;
    font-weight: bold;
    /* margin-left: 5px; */
}

.yichu {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;

    padding: 1px 0;
    padding-left: 4px;
}

.sleft {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-right: 12px solid #999;
    border-bottom: 8px solid transparent;
    margin-left: 12px;
    margin-right: 10px;
}

.yleft {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-left: 12px solid #999;
    border-bottom: 8px solid transparent;
    margin-left: 10px;
}

.flix {
    display: flex;
    align-items: center;
}

.fximg {
    width: 22px;
    height: 22px;
}

.heds {
    background: #fff;
    color: #000;
    height: 40px;
    line-height: 40px;
    font-size: 13px;
    text-align: center;
    border-bottom: 1px solid #f6f6f6;
}

.acvbar {
    background: #dcdcdc;
    color: #333;
}
</style>
